import { Controller } from "@hotwired/stimulus"
import { Datepicker } from 'vanillajs-datepicker'
import zhCN from 'vanillajs-datepicker/locales/zh-CN'
Object.assign(Datepicker.locales, zhCN)

// Connects to data-controller="date-picker"
export default class extends Controller {
  connect() {
    this.datepicker = new Datepicker(
      this.element,
      {
        language: 'zh-CN'
      })
  }
  disconnect(){
    this.datepicker.destroy()
  }
}
